import './Home.css';

import { SocialIcon } from 'react-social-icons';

const Home = (props) => {
    const iconBgColor = '#6b828f'
    const socialIconStyle = {
        height: 25,
        width: 25
    }

    return (
        <div>
            <div className="heading h1">
                Pearson Daniels
            </div>
            <div className="heading h2">
                Seattle, Washington, USA
            </div>
            <div className="social-icons">
                <div class='social-icon'>
                    <SocialIcon url="https://github.com/pearsondaniels" network="github" bgColor={iconBgColor} style={socialIconStyle} />
                </div>
                <div class='social-icon'>
                    <SocialIcon url="https://www.linkedin.com/in/pearsondaniels/" network="linkedin" bgColor={iconBgColor} style={socialIconStyle} />
                </div>
            </div>
        </div>
    );
}

export default Home;