import './App.css';

import Home from './Home';

function App() {
  return (
    <div className="app">
      <div className="main-content">
        <Home />
      </div>
    </div>
  );
}

export default App;
